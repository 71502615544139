import { Box, Chip, Pagination, Typography } from '@mui/material';
import { useEffect, useRef, useState, type ReactElement } from 'react';

import type {
  AssetListResponse,
  Dataset,
  Model,
  PaginationStatus
} from 'common/interfaces/interfaces';

import {
  ACCESS_TYPES,
  BACKEND_STRINGS,
  ICON_TYPE,
  MODAL_ACTIONS_ID,
  MODAL_INTENTS,
  MODAL_TYPES,
  ModalSize,
  RESTRICTIONS
} from 'common/interfaces/enums';

import Spinner from 'common/Spinner';
import ModalComposed from 'common/modal/ModalComposed';
import SmallCheckbox from 'common/smallCheckbox/SmallCheckbox';

import { modalControllerAtom } from 'atoms/atomModalController';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import {
  GAUserEvent,
  curringDataSearchParameters,
  formatDate
} from 'utils/utils';

import ColumnTableImage from 'assets/imgs/ai-set-2.png';
import CustomIcon from 'common/CustomIcon';

import {
  USER_TRACKING_DISPLAY_ASSETS_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './Load.scss';

const Load = (): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);

  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const assistantService = AssistantApiService.getInstance();
  const exposeService = ExposeService.getInstance();
  const authenticationService = AuthenticationService.getInstance();

  const [assets, setAssets] = useState<Array<Dataset | Model>>([]);
  const [viewVariants, setViewVariants] = useState<Record<string, number>>({});

  const [loading, setLoading] = useState<boolean>(true);
  const [isDataSet, setIsDataSet] = useState<boolean>(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ actual: 0, total: 0 });

  const [selectMerges, setSelectMerges] = useState<boolean>(false);
  const [selectedAssetsToMerge, setSelectedAssetsToMerge] = useState<Dataset[]>(
    []
  );

  const disableContinueButton = selectMerges
    ? selectedAssetsToMerge.length < 2
    : selectedAssetId === null;

  const radioButton = useRef<HTMLInputElement>(null);

  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );

  useEffect(() => {
    if (
      modalController.type === MODAL_TYPES.LOAD_MODELS ||
      modalController.type === MODAL_TYPES.LOAD_DATASETS
    ) {
      obtainData(1);
    } else if (modalController.type === MODAL_TYPES.SAVE_DATASET) {
      setIsDataSet(true);
      GAUserEvent('USER_DATASETS_SAVE');
    } else {
      setLoading(false);
    }
  }, []);

  const obtainData = (page: number): void => {
    const isShared = Boolean(sharedResources?.defaultValue);
    const filterOutShared = (assets: Model | Dataset): boolean => {
      if (isShared) {
        return true;
      }
      return (
        assets.visibility === ACCESS_TYPES.PRIVATE ||
        assets.visibility === ACCESS_TYPES.HIDDEN
      );
    };

    if (modalController.type === MODAL_TYPES.LOAD_MODELS) {
      setIsDataSet(false);
      exposeService
        .getUserModels(page)
        .then((modelReponse: AssetListResponse) => {
          const filteredData = modelReponse.data.filter(filterOutShared);
          setAssets(filteredData);
          updatePagination(
            page,
            modelReponse.collectionSize,
            modelReponse.data.length
          );
        })
        .catch(() => {
          setPagination({ actual: 0, total: 0 });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (modalController.type === MODAL_TYPES.LOAD_DATASETS) {
      setIsDataSet(true);
      // Make sure user is not accessing the nav bar my space
      // at the inference step in the conversation
      const disableAssetButton: boolean =
        modalController?.payload?.disable_asset_button ?? false;

      const tags =
        lastMessageIsInference() && !disableAssetButton
          ? [BACKEND_STRINGS.INFERENCE_ASSET_TAG]
          : [];

      exposeService
        .getUserDatasets(page, 10, tags)
        .then((datasetResponse: AssetListResponse) => {
          const datasets = adaptDatasetsToAssets(
            datasetResponse.data as Dataset[]
          );
          const filteredData = datasets.filter(filterOutShared);
          setAssets(filteredData);
          updatePagination(
            page,
            datasetResponse.collectionSize,
            datasetResponse.data.length
          );
        })
        .catch(() => {
          setPagination({ actual: 0, total: 0 });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const lastMessageIsInference = (): boolean => {
    // TODO: This is temporary until we implement a way to keep track of the context of the conversation.
    const lastMessage = lastResponse.blocks[0]?.text?.text;
    if (lastMessage !== undefined) {
      return lastMessage === BACKEND_STRINGS.UTTER_UPLOAD_INFERENCE_DATA;
    }
    return false;
  };

  const adaptDatasetsToAssets = (data: Dataset[]): Dataset[] => {
    const lastCreatedDate = getLastDate(data);
    const datasetsWithCreatedDate = data.map((element: Dataset) => {
      if (element.createdDate === undefined) {
        if (lastCreatedDate === undefined) {
          element.createdDate = element.timeOfUsage;
        } else {
          element.createdDate = lastCreatedDate;
        }
      }

      if (element.visibility === ACCESS_TYPES.HIDDEN) {
        element.tags.push(ACCESS_TYPES.TEMPORARILY_STORED);
      }

      return element;
    });
    return datasetsWithCreatedDate;
  };

  const getLastDate = (data: Dataset[]): string | undefined => {
    let lastCreatedDate;
    const elementWithCreatedDate = data.find(
      (element: Dataset) =>
        element.createdDate !== undefined && element.createdDate !== ''
    );
    if (elementWithCreatedDate?.createdDate !== undefined) {
      const createdDate = new Date(elementWithCreatedDate.createdDate);
      createdDate.setDate(createdDate.getDate() - 1);
      lastCreatedDate = createdDate.toISOString();
    } else {
      const elementWithTimeOfUsage = data.find(
        (element: Dataset) =>
          element.timeOfUsage !== undefined && element.timeOfUsage !== ''
      );
      if (elementWithTimeOfUsage?.timeOfUsage !== undefined) {
        const createdDate = new Date(elementWithTimeOfUsage.timeOfUsage);
        createdDate.setDate(createdDate.getDate() - 1);
        lastCreatedDate = createdDate.toISOString();
      }
    }
    return lastCreatedDate;
  };

  const handlePagination = (page: number): void => {
    obtainData(page);
  };

  const updatePagination = (
    page: number,
    collectionSize: number,
    dataLength: number
  ): void => {
    if (pagination.total === 0) {
      setPagination({
        actual: page,
        total: Math.ceil(collectionSize / dataLength)
      });
    } else {
      setPagination((prevPaginate: PaginationStatus) => {
        return { ...prevPaginate, actual: page };
      });
    }
  };

  const generateTags = (tags: string[]): ReactElement => {
    const getChipOfAsset = (tag: string, index: number): JSX.Element => {
      if (tag === ACCESS_TYPES.TEMPORARILY_STORED) {
        return (
          <Chip
            className="chip temporarily-stored"
            key={`${tag}-${index}`}
            label={tag}
          />
        );
      }
      return <Chip className="chip" key={`${tag}-${index}`} label={tag} />;
    };

    if (tags.length < 3) {
      return <Box className="tags">{tags.map(getChipOfAsset)}</Box>;
    }

    const chipsTags = tags.slice(0, 2);
    return (
      <Box className="tags">
        {[
          ...chipsTags.map(getChipOfAsset),
          <Chip className="chip" key={`dots-${2}`} label={'...'} />
        ]}
      </Box>
    );
  };

  const generateSpanDate = (asset: Dataset | Model): JSX.Element => {
    let componentSpan;
    if (
      'createdDate' in asset &&
      asset.createdDate !== undefined &&
      asset.createdDate !== ''
    ) {
      componentSpan = 'Date created: ' + formatDate(asset.createdDate);
    } else if (
      'timeOfUsage' in asset &&
      asset.timeOfUsage !== undefined &&
      asset.timeOfUsage !== ''
    ) {
      componentSpan = 'Last usage: ' + formatDate(asset.timeOfUsage);
    } else {
      return <></>;
    }
    return <span>{'· ' + componentSpan}</span>;
  };

  const handleRowSelection = (assetData: Dataset | Model): void => {
    if (isDataSet && selectMerges) {
      const isViewPosition = { isView: false, position: 0, id: assetData.id };
      const found = selectedAssetsToMerge.find(
        curringDataSearchParameters(isViewPosition, true)
      );
      if (found !== undefined) {
        setSelectedAssetsToMerge(
          selectedAssetsToMerge.filter((asset) => {
            return asset.id !== assetData.id && asset.name !== assetData.name;
          })
        );
      } else if (selectedAssetsToMerge.length < 2) {
        setSelectedAssetsToMerge([
          ...selectedAssetsToMerge,
          assetData as Dataset
        ]);
      }
    } else if (assetData.id !== undefined) {
      setSelectedAssetId(assetData.id);
    }
  };

  const handleContinue = (): void => {
    if (selectMerges) {
      GAUserEvent(
        `${userLocationVariable.current}_SELECT_${USER_TRACKING_DISPLAY_ASSETS_ACTIONS.MERGE_ACCESS}`
      );
      setModalController({
        type: MODAL_TYPES.MERGE_DATASETS,
        payload: {
          disable_asset_button: modalController.payload?.disable_asset_button,
          datasets: selectedAssetsToMerge
        }
      });
    } else if (selectedAssetId !== null) {
      const isViewPosition = {
        isView: false,
        position: 0,
        id: selectedAssetId
      };
      let selectedAssetData = assets.find(
        curringDataSearchParameters(isViewPosition)
      );
      if (
        isDataSet &&
        selectedAssetData !== undefined &&
        isViewPosition.isView
      ) {
        const { views } = selectedAssetData as Dataset;
        if (views !== undefined && views.data.length > 0) {
          selectedAssetData = views.data[isViewPosition.position];
        }
      }
      if (modalController.type === MODAL_TYPES.LOAD_DATASETS) {
        GAUserEvent(
          `${userLocationVariable.current}_${
            isViewPosition.isView
              ? USER_TRACKING_DISPLAY_ASSETS_ACTIONS.VERSION_SELECT
              : USER_TRACKING_DISPLAY_ASSETS_ACTIONS.DATASET_SELECT
          }`
        );
        setModalController({
          type: MODAL_TYPES.INSIGHT_DATASETS,
          payload: {
            ...modalController?.payload,
            ...selectedAssetData,
            isView: isViewPosition.isView,
            isInference: lastMessageIsInference()
          }
        });
      } else {
        GAUserEvent(
          `${userLocationVariable.current}_SELECT_${
            isViewPosition.isView
              ? USER_TRACKING_DISPLAY_ASSETS_ACTIONS.VERSION_SELECT
              : USER_TRACKING_DISPLAY_ASSETS_ACTIONS.MODEL_SELECT
          }`
        );
        setModalController({
          type: MODAL_TYPES.INSIGHT_MODEL,
          payload: { ...modalController?.payload, ...selectedAssetData }
        });
      }
    }
  };

  const closeModal = (): void => {
    const disableAssetButton: boolean =
      modalController?.payload?.disable_asset_button ?? false;
    if (!disableAssetButton) {
      assistantService.createCountDown().catch(console.error);
    }
    resetAtomModalController();
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    if (
      modalController.type === MODAL_TYPES.LOAD_MODELS &&
      !disableAssetButton
    ) {
      assistantService.continueConversationAfterLoadModal(
        MODAL_ACTIONS_ID.LOAD_MODELS,
        MODAL_INTENTS.CANCEL_LOAD_MODEL,
        {}
      );
    }
  };

  const renderDatasetMergeChecks = (id: string): JSX.Element => {
    const found = selectedAssetsToMerge.find(
      curringDataSearchParameters({ id }, true)
    );
    return <SmallCheckbox found={found !== undefined} />;
  };

  const headerComponent = (
    <>
      <Typography className="modalTitle color-dark">
        {selectMerges
          ? 'Merge datasets'
          : `Select a ${isDataSet ? 'dataset' : 'model'}`}
      </Typography>
    </>
  );

  const bodyComponent = (
    <Box className="load-modal-body">
      <Box className="subheader-load">
        <Typography className="ft-md">
          Choose between any of the {isDataSet ? 'datasets' : 'models'} stored
          in your profile in the list below
        </Typography>
        {isDataSet ? (
          <CustomButton
            variant="secondary"
            disabled={loading}
            onClick={() => {
              if (!selectMerges && selectedAssetsToMerge.length > 0) {
                setSelectedAssetsToMerge([]);
              }
              setSelectedAssetId(null);
              setSelectMerges(!selectMerges);
            }}
            icon={{
              type: selectMerges ? ICON_TYPE.MERGE_DATA : ICON_TYPE.LINK03,
              position: 'left'
            }}
          >
            {selectMerges ? 'Cancel merge datasets' : 'Merge datasets'}
          </CustomButton>
        ) : (
          <></>
        )}
      </Box>
      {loading ? (
        <Box className="spinner-box">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box className="table-container">
            <table className="radio-table">
              <thead>
                <tr style={{ position: 'sticky', top: '0' }}>
                  <th style={{ paddingLeft: '11%' }}>
                    <div>Name</div>
                  </th>
                  <th style={{ width: '120px' }}></th>
                  <th>
                    <div>Access</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {assets.map((asset, index: number) => {
                  let data: Dataset[] | [] = [];
                  let collectionSize = 0;
                  const viewIsOpen =
                    viewVariants[asset.id] !== undefined &&
                    viewVariants[asset.id] !== 0;
                  if (
                    'views' in asset &&
                    asset.views !== undefined &&
                    asset.views.data !== undefined &&
                    asset.views.collectionSize !== undefined
                  ) {
                    data = asset.views.data;
                    collectionSize = asset.views.collectionSize;
                  }
                  const originalAssetRow = (
                    <tr
                      key={`${asset.id}-${index}-${Math.random()}}`}
                      className={selectedAssetId === asset.id ? 'select' : ''}
                    >
                      <td
                        onClick={() => {
                          handleRowSelection(asset);
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {selectMerges ? (
                            renderDatasetMergeChecks(asset.id)
                          ) : (
                            <input
                              type="radio"
                              name="model"
                              id={asset.id.toString()}
                              value={asset.id}
                              ref={radioButton}
                              checked={selectedAssetId === asset.id}
                              readOnly
                            />
                          )}
                          <img
                            className="patternImage"
                            src={ColumnTableImage}
                            alt="Column Image"
                          />
                          <div className="model-card">
                            <label htmlFor={asset.id.toString()}>
                              {asset.name}
                            </label>
                            <div className="model-metadata">
                              {generateTags(asset.tags)}
                              {generateSpanDate(asset)}
                            </div>
                          </div>
                        </div>
                      </td>
                      {collectionSize > 0 ? (
                        <td>
                          <CustomButton
                            variant="secondary"
                            small={true}
                            disableRipple={true}
                            disabled={selectMerges}
                            style={{
                              maxWidth: 'none',
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                              textTransform: 'lowercase',
                              position: 'unset'
                            }}
                            icon={{
                              type:
                                viewIsOpen && !selectMerges
                                  ? ICON_TYPE.CHEVRON_UP
                                  : ICON_TYPE.CHEVRON_DONW,
                              position: 'right'
                            }}
                            onClick={() => {
                              if (viewIsOpen) {
                                setViewVariants({
                                  ...viewVariants,
                                  [asset.id]: 0
                                });
                              } else {
                                setViewVariants({
                                  ...viewVariants,
                                  [asset.id]: 2
                                });
                              }
                            }}
                          >
                            {viewIsOpen && !selectMerges
                              ? 'hide versions'
                              : `${collectionSize}  versions`}
                          </CustomButton>
                        </td>
                      ) : (
                        <td
                          onClick={() => {
                            handleRowSelection(asset);
                          }}
                        ></td>
                      )}
                      <td
                        onClick={() => {
                          handleRowSelection(asset);
                        }}
                      >
                        <CustomIcon
                          className={
                            selectedAssetId === asset.id
                              ? 'light-icon-no-hover'
                              : ''
                          }
                          style={{
                            maxWidth: '30px',
                            margin: '5px',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          type={
                            asset.visibility === ACCESS_TYPES.PRIVATE ||
                            asset.visibility === ACCESS_TYPES.HIDDEN
                              ? ICON_TYPE.LOCK01
                              : ICON_TYPE.USERS01
                          }
                        />
                      </td>
                    </tr>
                  );

                  if (viewIsOpen && !selectMerges) {
                    data = data.sort((a: Dataset, b: Dataset) => {
                      return (
                        new Date(b.createdDate).getTime() -
                        new Date(a.createdDate).getTime()
                      );
                    });
                    const viewAssetsRow = [];
                    if (data.length > 2) {
                      viewAssetsRow.push(
                        <tr
                          onClick={() => {
                            if (viewVariants[asset.id] >= 2) {
                              setViewVariants({
                                ...viewVariants,
                                [asset.id]: viewVariants[asset.id] + 2
                              });
                            }
                          }}
                        >
                          <td>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <CustomIcon
                                style={{
                                  marginLeft: '40px',
                                  marginRight: '15px'
                                }}
                                className="patternImage"
                                type={ICON_TYPE.MID_CHILD}
                              />
                              <div className="model-card-view show-pattern-image">
                                <label>Show more</label>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    }
                    for (let i = 0; i < viewVariants[asset.id]; i++) {
                      const viewfillData = data[i];
                      const isLast = data[i + 1];

                      if (viewfillData !== undefined) {
                        let filledDiv = (
                          <CustomIcon
                            style={{ marginLeft: '15px', marginRight: '15px' }}
                            className="patternImage mid-pattern-image"
                            type={ICON_TYPE.MID_CHILD}
                          />
                        );
                        if (isLast === undefined) {
                          filledDiv = (
                            <CustomIcon
                              style={{
                                marginLeft: '15px',
                                marginRight: '15px'
                              }}
                              className="patternImage last-pattern-image"
                              type={ICON_TYPE.LAST_CHILD}
                            />
                          );
                        }
                        viewAssetsRow.push(
                          <tr>
                            <td
                              onClick={() => {
                                handleRowSelection(viewfillData);
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {selectMerges ? (
                                  renderDatasetMergeChecks(viewfillData.id)
                                ) : (
                                  <input
                                    type="radio"
                                    name="model"
                                    id={viewfillData.id.toString()}
                                    value={viewfillData.id}
                                    ref={radioButton}
                                    checked={
                                      selectedAssetId === viewfillData.id
                                    }
                                    readOnly
                                  />
                                )}
                                {filledDiv}
                                <div className="model-card-view">
                                  <label htmlFor={viewfillData.id.toString()}>
                                    {viewfillData.name}
                                  </label>
                                  <div className="model-metadata">
                                    {generateTags(viewfillData.tags)}
                                    {generateSpanDate(viewfillData)}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              onClick={() => {
                                handleRowSelection(viewfillData);
                              }}
                            ></td>
                            <td
                              onClick={() => {
                                handleRowSelection(viewfillData);
                              }}
                            >
                              <CustomIcon
                                className={
                                  selectedAssetId === viewfillData.id
                                    ? 'light-icon-no-hover'
                                    : ''
                                }
                                style={{
                                  maxWidth: '30px',
                                  margin: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                                type={
                                  viewfillData.visibility ===
                                    ACCESS_TYPES.PRIVATE ||
                                  viewfillData.visibility ===
                                    ACCESS_TYPES.HIDDEN
                                    ? ICON_TYPE.LOCK01
                                    : ICON_TYPE.USERS01
                                }
                              />
                            </td>
                          </tr>
                        );
                      }
                    }
                    return (
                      <>
                        {originalAssetRow}
                        {viewAssetsRow}
                      </>
                    );
                  }
                  return originalAssetRow;
                })}
              </tbody>
            </table>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Pagination
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                handlePagination(page);
              }}
              className="pagination"
              count={pagination.total}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        </>
      )}
    </Box>
  );

  const footerComponent = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          gap: '20px'
        }}
      >
        <CustomButton variant="secondary" onClick={closeModal}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="primary"
          disabled={disableContinueButton}
          onClick={handleContinue}
        >
          {selectedAssetsToMerge.length === 2
            ? 'Merge datasets'
            : isDataSet
            ? 'Select dataset'
            : 'Continue'}
        </CustomButton>
      </div>
    </>
  );

  return (
    <ModalComposed
      open={
        modalController.type === MODAL_TYPES.LOAD_MODELS ||
        modalController.type === MODAL_TYPES.LOAD_DATASETS
      }
      size={ModalSize.COMPOSED_FULL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default Load;
