import React, { useRef, useState, type ReactElement } from 'react';

import { Checkbox, ListItemText, MenuItem } from '@mui/material';

import { type BotComponentSubmit } from 'chat/Chat';
import { USER_MESSAGE } from 'chat/interfaces/enums';

import { AssistantApiService } from 'chat/services/AssistantApiService';

import Select, { type SelectChangeEvent } from '@mui/material/Select';

import { Check, KeyboardArrowDown } from '@mui/icons-material';
import {
  TRACKED_USER_ACTION_OPTIMISE_FLOW,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import type {
  MultiSelectOptions,
  ValidTargetFeatures
} from 'chat/interfaces/messages';
import CustomButton from 'common/button/CustomButton';
import type { SelectedMultiSelectOptions } from 'common/interfaces/interfaces';
import { useRecoilValue } from 'recoil';
import { GAUserEvent } from 'utils/utils';

import './MultiSelect.scss';

interface MultiSelectProps extends BotComponentSubmit {
  options: MultiSelectOptions;
  collapseIsland: () => void;
}

const MultiSelect = ({
  options,
  addNewMessages,
  collapseIsland
}: MultiSelectProps): ReactElement => {
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [selectedOptimizationType, setSelectedOptimizationType] =
    useState<string>('');
  const [selectedCategoryVariant, setSelectedCategoryVariant] =
    useState<string>('');
  const [disregardColumns, setDisregardColumns] = React.useState<string[]>([]);

  const assistantApiService = AssistantApiService.getInstance();

  const submitValue = (): void => {
    collapseIsland();
    let returnObj: SelectedMultiSelectOptions = {
      messages: '',
      targetColumn: '',
      disregardColumns: []
    };

    // If no disregard columns, set to empty array
    let columnsToDisregard = disregardColumns;
    let disregardMessage =
      ' while disregarding column(s) ' + disregardColumns.join(', ') + '.';
    if (disregardColumns.length === 1 && disregardColumns[0] === 'None') {
      columnsToDisregard = [];
      disregardMessage = ' without disregarding any column.';
    }

    // Generate selected objective statement
    let message = 'I would like to ';
    if (isOptimise.current) {
      message += `optimise ${selectedColumn} by ${selectedOptimizationType}`;
      message +=
        getCategoryVariants().length > 0
          ? ` ${selectedCategoryVariant}`
          : ' it';
      message += disregardMessage;
      returnObj = {
        targetColumn: selectedColumn,
        optimizationType: selectedOptimizationType,
        optimiseVariant: selectedCategoryVariant,
        disregardColumns: columnsToDisregard,
        messages: message
      };
    } else {
      message += `predict ${selectedColumn}${disregardMessage}`;
      returnObj = {
        targetColumn: selectedColumn,
        disregardColumns: columnsToDisregard,
        messages: message
      };
    }
    GAUserEvent(
      `${userLocationVariable.current}_${TRACKED_USER_ACTION_OPTIMISE_FLOW.SET_GOAL}`
    );
    addNewMessages(returnObj.messages, USER_MESSAGE.MESSAGE, true);
    assistantApiService.multiSelectOption(
      'OBJECTIVE_STATEMENT_MENU_DATA',
      returnObj
    );
  };

  const isOptimise = useRef<boolean>(options?.objective === 'optimise');

  const classes = {
    select: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      fontFamily: 'ft-system-regular',
      height: '48px',
      background: '#FFFFFF',
      borderRadius: '8px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A82AAB !important',
        borderRadius: '8px'
      }
    }
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: '100%',
        boxSizing: 'borderBox',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        position: 'absolute',
        background: '#FFFFFF',
        border: '1px solid #F2F4F7',
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        borderRadius: '8px',
        margin: '-5px 0px',
        '& .MuiList-root': {
          maxHeight: '20rem',
          width: '100%'
        },
        '& .MuiButtonBase-root': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 14px',
          gap: '8px',
          height: '44px',
          flex: 'none',
          order: '0',
          alignSelf: 'stretch',
          flexGrow: '0',
          borderRadius: '8px',
          marginBottom: '3px',
          '&:hover': {
            background: 'rgba(168, 42, 171, 0.73)',
            color: 'white'
          }
        },
        '& .Mui-selected': {
          background: '#F2F4F7',
          '&:hover': {
            background: 'rgba(168, 42, 171, 0.73)',
            color: 'white'
          }
        }
      }
    },
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'top'
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const CheckmarkMenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: '8px',
        '& .MuiMenuItem-root': {
          backgroundColor: 'red !important'
        }
      }
    }
  };

  const getColumns = (targetFeaturesOnly = true): string[] => {
    if (!targetFeaturesOnly && selectedColumn.length > 0) {
      // Return disregarding options excluding selected target column
      return options?.allFeatures.filter(
        (key: string) => key !== selectedColumn
      );
    }

    let validTargetFeatures: ValidTargetFeatures = [];

    if (isOptimise.current) {
      validTargetFeatures = Object.keys(options?.validTargetFeatures);
    } else {
      validTargetFeatures = options?.validTargetFeatures;
    }

    return validTargetFeatures as string[];
  };

  const getCategoryVariants = (): string[] => {
    const optionsValidTargetFeatures = JSON.parse(
      JSON.stringify(options?.validTargetFeatures)
    );
    return selectedColumn !== ''
      ? optionsValidTargetFeatures[selectedColumn]
      : [];
  };

  const updateDisregardColumns = (
    event: SelectChangeEvent<typeof disregardColumns>
  ): void => {
    const {
      target: { value }
    } = event;

    const selectedValues = typeof value === 'string' ? value.split(',') : value;

    // Check if the selectedValues array contains an empty string value
    const hasEmptyValue = selectedValues.includes('');

    if (hasEmptyValue) {
      // If an empty value is found it means clear-all, add the None placeholder
      setDisregardColumns(['None']);
    } else {
      // Otherwise, update the disregardColumns state with the selectedValues array
      if (selectedValues.includes('None')) {
        selectedValues.shift();
      }
      setDisregardColumns(selectedValues);
    }
  };

  const disableButton = (): boolean => {
    if (selectedColumn === '') {
      return true;
    }

    if (isOptimise.current) {
      if (selectedOptimizationType === '') {
        return true;
      }

      if (getCategoryVariants().length > 0 && selectedCategoryVariant === '') {
        return true;
      }
    }

    return false;
  };

  return (
    <React.Fragment>
      <div className="select-row">
        <label>I would like to {options?.objective}</label>

        <Select
          className="CustomMultiSelect"
          defaultValue=""
          sx={classes.select}
          onChange={(e) => {
            setSelectedColumn(e?.target?.value);
            setSelectedCategoryVariant('');
            setDisregardColumns(['None']);
          }}
          // @ts-expect-error MUI type does not expect the value prop but it works
          MenuProps={MenuProps}
          IconComponent={KeyboardArrowDown}
        >
          {options !== undefined &&
            getColumns().map((value) => (
              <MenuItem key={`option-${value}`} value={value}>
                {value}
                {selectedColumn === value ? <Check /> : <></>}
              </MenuItem>
            ))}
        </Select>
      </div>

      {isOptimise.current && (
        <div className="select-row">
          <label>by</label>

          <Select
            className="CustomMultiSelect"
            defaultValue=""
            sx={classes.select}
            onChange={(e) => {
              setSelectedOptimizationType(e?.target?.value);
            }}
            // @ts-expect-error MUI type does not expect the value prop but it works
            MenuProps={MenuProps}
            IconComponent={KeyboardArrowDown}
            disabled={!(selectedColumn !== '')}
          >
            {['increasing', 'decreasing'].map((value) => (
              <MenuItem key={`option-${value}`} value={value}>
                {value}
                {selectedOptimizationType === value ? <Check /> : <></>}
              </MenuItem>
            ))}
          </Select>

          {selectedColumn !== '' && getCategoryVariants().length > 0 ? (
            <Select
              className="CustomMultiSelect"
              value={selectedCategoryVariant}
              style={{ marginLeft: '10px' }}
              sx={classes.select}
              onChange={(e) => {
                setSelectedCategoryVariant(e?.target?.value);
              }}
              // @ts-expect-error MUI type does not expect the value prop but it works
              MenuProps={MenuProps}
              IconComponent={KeyboardArrowDown}
              disabled={selectedColumn === ''}
            >
              {getCategoryVariants().map((value) => (
                <MenuItem key={`option-${value}`} value={value}>
                  {value}
                  {selectedCategoryVariant === value ? <Check /> : <></>}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <></>
          )}

          {selectedColumn !== '' && !(getCategoryVariants().length > 0) && (
            <label>it.</label>
          )}
        </div>
      )}

      <div className="select-row">
        <label>while disregarding column(s)</label>
        <Select
          multiple
          sx={classes.select}
          value={disregardColumns}
          onChange={updateDisregardColumns}
          IconComponent={KeyboardArrowDown}
          disabled={selectedColumn === ''}
          renderValue={(selected) => {
            return selected.join(', ');
          }}
          MenuProps={CheckmarkMenuProps}
        >
          <MenuItem value="">
            <em>Clear All</em>
          </MenuItem>
          {options !== undefined &&
            getColumns(false).map((key) => (
              <MenuItem
                className="menu-item--checkbox"
                style={{ fontFamily: 'ft-system-regular' }}
                key={`option-${key}`}
                value={key}
              >
                <Checkbox checked={disregardColumns.includes(key)} />
                <ListItemText primary={key} />
              </MenuItem>
            ))}
        </Select>
      </div>

      <CustomButton
        disabled={disableButton()}
        onClick={() => {
          submitValue();
        }}
        style={{ maxWidth: 'none' }}
      >
        Confirm
      </CustomButton>
    </React.Fragment>
  );
};

export default MultiSelect;
