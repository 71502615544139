import { useEffect, useRef, useState, type ReactElement } from 'react';

import {
  Alert,
  Box,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
  type AlertColor
} from '@mui/material';

import type {
  Dataset,
  DatasetSchema,
  Model,
  NotifyBoxTypes,
  SaveUploadedTypes,
  TableData
} from 'common/interfaces/interfaces';

import {
  ACCESS_TYPES,
  ICON_TYPE,
  MODAL_ACTIONS_ID,
  MODAL_INTENTS,
  MODAL_TYPES,
  ModalSize,
  NUMBERS_STRINGS,
  RESTRICTIONS
} from 'common/interfaces/enums';

import type { User } from 'models/User';

import CustomIcon from 'common/CustomIcon';
import ModalComposed from 'common/modal/ModalComposed';
import AssetsForm from 'mySpace/components/assetsInputsByIntent/AssetsForm';
import Delete from 'mySpace/delete/DeleteAsset';

import { KeyboardArrowDown } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';

import { modalControllerAtom } from 'atoms/atomModalController';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import PreviewDataset from 'featureEngineering/previewDataset/PreviewDataset';
import PreviewRows from 'featureEngineering/previewRows/PreviewRows';
import SwitchTransformer from 'mySpace/components/switchTransformer/SwitchTransformer';
import { FeatureType } from 'playground/interfaces/playground';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent, formatDate } from 'utils/utils';

import {
  USER_TRACKING_DETAILS_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './Details.scss';

interface CONTEXT_TYPE {
  model_transparency: string;
  test_performance: { 'R-squared'?: number };
}

const CustomTooltip = (): ReactElement => (
  <Tooltip
    title={
      <Box
        className="display-column ft-xxs"
        sx={{ padding: '0.2em', gap: '6px' }}
      >
        <p style={{ margin: 0 }}>“Private” assets can only be seen by you.</p>
        <p style={{ margin: 0 }}>
          “Profile shared” assets can be seen by any user added to your
          company&apos;s account
        </p>
      </Box>
    }
    placement="top"
    arrow
  >
    <div>
      <CustomIcon type={ICON_TYPE.HELP_CIRCLE} />
    </div>
  </Tooltip>
);

const Details = (): ReactElement => {
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const setMessage = useSetRecoilState(magicMessageDerived);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);

  const assistantService = AssistantApiService.getInstance();
  const authenticationService = AuthenticationService.getInstance();
  const exposeService = ExposeService.getInstance();

  const isDataSet = modalController.type === MODAL_TYPES.INSIGHT_DATASETS;
  const [asset, setAsset] = useState<Dataset | Model>(modalController.payload);
  const [name, setName] = useState<string>(asset.name ?? '');
  const [description, setDescription] = useState<string>(
    asset.description ?? ''
  );
  const [validName, setValidName] = useState<boolean>(false);

  const [preview, setPreview] = useState<DatasetSchema[]>();
  const [rowsPreview, setRowsPreview] = useState<TableData>();
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [detailPreview, setDetailPreview] = useState<string>(
    NUMBERS_STRINGS.FIRST
  );

  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);
  const [insightsEditMode, setInsightsEditMode] = useState<boolean>(false);

  const [sendData, setSendData] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [dataContext, setDataContext] = useState<null | {
    original_name: string;
  }>(null);
  const [context, setContext] = useState<null | CONTEXT_TYPE>(null);
  const [performance, setPerformance] = useState<null | number>(null);
  const disableUseAssetButton: boolean =
    modalController?.payload?.disable_asset_button ?? false;

  const user: null | User = authenticationService.userData;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<ACCESS_TYPES>(
    asset.visibility ?? ACCESS_TYPES.PRIVATE
  );
  const recivedTags = asset.tags ?? [];
  const [tags, setTags] = useState<string[]>(recivedTags);

  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );
  const access = authenticationService.getAccess();

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const isUsersModal = user?.id === asset?.userId;
  const approveSave = insightsEditMode && name !== '' && validName;

  const checkDatasetSchemaPresent = (): boolean => {
    if (isDataSet && 'schema' in asset) {
      return (asset.schema ?? []).length === 0;
    }
    return true;
  };

  const datasetSchemaPresent = checkDatasetSchemaPresent();

  const [snackBar, setSnackBar] = useState<{
    status: AlertColor;
    open: boolean;
    message: string;
  }>({ status: 'info', open: false, message: '' });

  useEffect(() => {
    if (name === '' && asset.name !== undefined) {
      setName(asset.name);
    }
    if (description === '' && asset.description !== undefined) {
      setDescription(asset.description);
    }
    if (
      dataContext === null &&
      'dataContext' in asset &&
      asset?.dataContext !== undefined
    ) {
      setDataContext(JSON.parse(asset.dataContext));
    }
    if (
      context === null &&
      'context' in asset &&
      asset?.context !== undefined &&
      asset.context !== ''
    ) {
      const parsedContext = JSON.parse(asset.context);
      const parsedContextValue = Object.values(parsedContext)[0];
      if (parsedContextValue !== undefined) {
        setContext(parsedContextValue as CONTEXT_TYPE);
      }
    }
  }, [asset]);

  useEffect(() => {
    if (
      performance === null &&
      'performanceMetric' in asset &&
      typeof asset?.performanceMetric === 'string' &&
      context?.test_performance !== undefined
    ) {
      type testPerformance = keyof typeof context.test_performance;
      const performanceMetric = asset.performanceMetric.replace(/\\|"/g, '');
      const foundPerformance: number | undefined =
        context.test_performance[performanceMetric as testPerformance];
      if (foundPerformance !== undefined) {
        setPerformance(foundPerformance);
      }
    }
  }, [context]);

  useEffect(() => {
    if (!insightsEditMode) {
      if (nameRef.current !== null && name !== nameRef.current?.value) {
        setName(asset.name);
      }
      if (
        descriptionRef.current !== null &&
        description !== descriptionRef.current?.value
      ) {
        setDescription(asset.description);
      }
      if (
        tags.length !== recivedTags.length ||
        JSON.stringify(tags) !== JSON.stringify(recivedTags)
      ) {
        setTags(recivedTags);
      }
    }
  }, [insightsEditMode]);

  useEffect(() => {
    if (detailPreview === NUMBERS_STRINGS.SECOND && rowsPreview === undefined) {
      setPreviewLoading(false);
      setSnackBar({
        status: 'error',
        open: true,
        message: 'Dataset preview not available'
      });
    }
  }, [detailPreview]);

  const resetData = (): void => {
    setContext(null);
    setDataContext(null);
    setPerformance(null);
    setMessageError(null);
    setName('');
    setDescription('');
  };

  const handleSave = (): void => {
    setSendData(true);

    const filteredTags = tags.filter(
      (tag) => tag !== ACCESS_TYPES.TEMPORARILY_STORED
    );

    const collectedSaveData: SaveUploadedTypes = {
      name,
      description,
      visibility,
      tags: filteredTags
    };

    const isView = (asset as Dataset).datasetId;

    if (isView !== undefined) {
      exposeService
        .updateView(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataSet ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({
              type: 'error',
              message: 'View data not found'
            });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          setMessageError({ type: 'error', message: error.message });
        });
    } else if (isDataSet) {
      exposeService
        .updateDataset(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataSet ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({
              type: 'error',
              message: 'Dataset data not found'
            });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          setMessageError({ type: 'error', message: error.message });
        });
    } else {
      exposeService
        .updateModel(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            resetData();
            setAsset(data);
            setInsightsEditMode(false);
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataSet ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
          } else {
            setMessageError({ type: 'error', message: 'Model data not found' });
          }
        })
        .finally(() => {
          setSendData(false);
        })
        .catch((error) => {
          setMessageError({ type: 'error', message: error.message });
        });
    }
  };

  const handleUseModel = (): void => {
    if (approveSave) {
      handleSave();
    } else {
      if (isDataSet) {
        const isInference =
          'isInference' in modalController.payload &&
          Boolean(modalController.payload.isInference);

        const isView =
          'isView' in modalController.payload &&
          Boolean(modalController.payload.isView);

        if (isInference && 'datasetUrl' in asset) {
          let assetUrl = asset.datasetUrl;

          if (
            isView &&
            'viewUrl' in asset &&
            typeof asset.viewUrl === 'string'
          ) {
            assetUrl = asset.viewUrl;
          }
          assistantService.sendFileLink(assetUrl);
        } else {
          assistantService.continueConversationAfterLoadModal(
            MODAL_ACTIONS_ID.LOAD_DATASETS,
            MODAL_INTENTS.CHOOSE_LOADED_DATASET,
            asset
          );
        }
      } else {
        assistantService.continueConversationAfterLoadModal(
          MODAL_ACTIONS_ID.LOAD_MODELS,
          MODAL_INTENTS.CHOOSE_LOADED_MODEL,
          asset
        );
      }
      GAUserEvent(
        `${userLocationVariable.current}_${
          (isDataSet ? 'DATASET_' : 'MODEL_') +
          USER_TRACKING_DETAILS_ACTIONS.USE
        }`
      );
      setUserLocationVariable({
        ...userLocationVariable,
        current: userLocationVariable.previous,
        previous: userLocationVariable.current
      });
      resetAtomModalController();
      setMessage(undefined);
    }
  };

  const onEditEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (approveSave) handleSave();
    }
  };

  const getPreview = async (): Promise<void> => {
    GAUserEvent(
      `${userLocationVariable.current}_${
        (isDataSet ? 'DATASET_' : 'MODEL_') +
        USER_TRACKING_DETAILS_ACTIONS.PREVIEW
      }`
    );
    setPreviewLoading(true);
    const datasetAsset = asset as Dataset;
    const previewResponse =
      'isView' in modalController.payload &&
      Boolean(modalController.payload.isView)
        ? await exposeService.getViewRows(
            datasetAsset.datasetId,
            datasetAsset.id
          )
        : await exposeService.getDatasetRows(datasetAsset.id);
    if (
      previewResponse?.rows !== undefined &&
      previewResponse.rows.length > 0
    ) {
      setRowsPreview({
        rows: previewResponse.rows
      });
    }
    if (
      datasetAsset.schema !== undefined &&
      Array.isArray(datasetAsset.schema) &&
      datasetAsset.schema.length > 0
    ) {
      setPreview(datasetAsset.schema);
      setPreviewLoading(false);
    }
  };

  const closeModal = (): void => {
    if (!disableUseAssetButton) {
      assistantService.createCountDown().catch(console.error);
    }
    if (!isDataSet && !disableUseAssetButton) {
      assistantService.continueConversationAfterLoadModal(
        MODAL_ACTIONS_ID.LOAD_MODELS,
        MODAL_INTENTS.CANCEL_LOAD_MODEL,
        {}
      );
    }
    resetAtomModalController();
  };

  const handleCancel = (): void => {
    if (insightsEditMode) {
      setInsightsEditMode(false);
    } else {
      closeModal();
    }
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const openDeleteModalAction = (): void => {
    setOpenDeleteModal(true);
  };

  const generateMenuList = (): JSX.Element[] => {
    const list = [
      <MenuItem
        className="menu-item"
        key="menu-item-dowload"
        disabled
        onClick={() => {
          closeMenu();
        }}
      >
        <CustomIcon type={ICON_TYPE.DOWNLOAD01} />
        <Typography>Download</Typography>
      </MenuItem>
    ];

    if (isUsersModal) {
      list.push(
        <MenuItem
          className="menu-item"
          key="menu-item-edit"
          onClick={() => {
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataSet ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.EDIT
              }`
            );
            setInsightsEditMode(true);
            closeMenu();
          }}
          data-cy="insights-modal-edit-button"
        >
          <CustomIcon type={ICON_TYPE.EDIT02} />
          <Typography>Edit</Typography>
        </MenuItem>,
        <MenuItem
          className="menu-item"
          key="menu-item-delete"
          onClick={() => {
            closeMenu();
            openDeleteModalAction();
          }}
        >
          <CustomIcon type={ICON_TYPE.TRASH01} />
          <Typography>Delete</Typography>
        </MenuItem>
      );
    } else {
      list.push(
        <MenuItem
          className="menu-item"
          key="menu-item-hide"
          disabled
          onClick={() => {
            closeMenu();
          }}
        >
          <CustomIcon type={ICON_TYPE.EYEOFF} />
          <Typography>Hide</Typography>
        </MenuItem>
      );
    }
    return list;
  };

  const generateDate = (): string => {
    if (
      isDataSet &&
      'timeOfUsage' in asset &&
      asset.timeOfUsage !== undefined
    ) {
      return formatDate(asset.timeOfUsage);
    } else if ('createdDate' in asset && asset.createdDate !== undefined) {
      return formatDate(asset.createdDate);
    }
    return '';
  };

  const getAssetAccess = (): ReactElement => {
    if (
      Boolean(sharedResources?.defaultValue) &&
      asset.visibility !== 'private'
    ) {
      return (
        <Chip
          className="chip"
          icon={<CustomIcon type={ICON_TYPE.USERS01} />}
          label={'Account Shared'}
        />
      );
    }

    return (
      <Chip
        className="chip"
        label={'Private'}
        icon={<CustomIcon type={ICON_TYPE.LOCK01} />}
      />
    );
  };

  const generatePreviewTables = (): ReactElement => {
    return (
      <Box className="display-column">
        <Box className="insights-head">
          <Typography className="insights-head-title color-dark">
            Dataset Preview
          </Typography>
          <SwitchTransformer
            detailPreview={detailPreview}
            setDetailPreview={setDetailPreview}
          />
        </Box>
        <Box className="insights-block">
          <Box
            className="insights-preview-block"
            style={{
              display:
                detailPreview === NUMBERS_STRINGS.SECOND &&
                rowsPreview !== undefined
                  ? 'contents'
                  : 'flex'
            }}
          >
            {detailPreview === NUMBERS_STRINGS.FIRST &&
              preview !== undefined && <PreviewDataset tableData={preview} />}
            {detailPreview === NUMBERS_STRINGS.SECOND &&
            rowsPreview !== undefined ? (
              <PreviewRows tableData={rowsPreview} />
            ) : (
              previewLoading && <LoadingDots />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const generatePreviewButton = (): ReactElement => {
    if (preview !== undefined) {
      return generatePreviewTables();
    }
    return (
      <CustomButton
        variant="secondary"
        style={{
          marginTop: '10px',
          width: 'max-content'
        }}
        onClick={getPreview}
      >
        {previewLoading ? <LoadingDots /> : 'Preview Dataset'}
      </CustomButton>
    );
  };

  const openDataTransformationView = (): void => {
    // If user chooses to edit a dataset version (view), the asset context (that holds dataset id and url) is empty string
    const payload = {
      datasetId: '',
      originalDatasetId: '',
      datasetUrl: '',
      dataManagementFlow: true
    };

    if (asset.context !== null && asset.context !== '') {
      // We are working with dataset
      const assetContextObj = JSON.parse(asset.context);
      payload.datasetId = assetContextObj.dataset?.dataset_id ?? '';
      payload.originalDatasetId =
        assetContextObj.dataset?.original_dataset_id ?? '';
      payload.datasetUrl = 'datasetUrl' in asset ? asset.datasetUrl : '';
    } else {
      // We are working with dataset version (view)
      payload.datasetId = asset.id;
      payload.originalDatasetId = 'datasetId' in asset ? asset.datasetId : '';
      payload.datasetUrl = 'viewUrl' in asset ? (asset.viewUrl as string) : '';
    }

    setModalController({
      type: MODAL_TYPES.TRANSFORMATION,
      payload
    });
  };

  const openCrossTabsView = (): void => {
    const datasetAsset = asset as Dataset;
    const numericalCategoricalSchema = datasetAsset.schema?.reduce(
      (acumulator: string[], e: DatasetSchema) => {
        if (
          e.dataValue === FeatureType.NUMERICAL ||
          e.dataValue === FeatureType.CATEGORICAL ||
          e.dataValue === FeatureType.BINARY_CATEGORICAL
        ) {
          return [...acumulator, e.name];
        }
        return acumulator;
      },
      []
    );
    const datasetId =
      modalController?.payload?.datasetId ?? modalController?.payload?.id;
    const viewId = datasetId === datasetAsset.id ? undefined : datasetAsset.id;
    const payload = {
      datasetId: datasetAsset.id,
      mainGroup: numericalCategoricalSchema,
      ...modalController?.payload
    };
    if (viewId !== undefined) {
      payload.viewId = viewId;
    }
    setModalController({
      type: MODAL_TYPES.CROSS_TABS,
      payload
    });
  };

  const headerComponent = (
    <Box className="modal-header-details">
      <Chip
        className="insights-header-chip"
        label={isDataSet ? 'Dataset' : 'Model'}
      />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        {context?.model_transparency !== undefined ? (
          <Chip
            className="insights-header-chip"
            label={`Transparency ${context?.model_transparency}`}
          />
        ) : null}
        {performance !== null ? (
          <Chip
            className="insights-header-chip"
            label={`Precision ${(+performance).toFixed(2)}`}
          />
        ) : null}
        {isDataSet &&
        'numTimesCalled' in asset &&
        asset?.numTimesCalled !== undefined ? (
          <Chip
            className="insights-header-chip"
            label={`Contributions ${asset.numTimesCalled}`}
          />
        ) : null}
      </Box>
    </Box>
  );

  const bodyComponent = insightsEditMode ? (
    <Box className="details-body">
      <AssetsForm
        modalController={modalController.type}
        inputControllers={{
          stateName: { name, setName, nameRef, validName, setValidName },
          stateDescription: { description, setDescription, descriptionRef },
          stateAccess: { access, visibility, setVisibility },
          stateTags: [tags, setTags],
          shared: Boolean(sharedResources?.defaultValue)
        }}
        onEnter={onEditEnter}
        disableInputs={sendData}
        messageError={messageError}
      />
    </Box>
  ) : (
    <Box className="display-row details-body">
      <Box className="insights-section-one display-column">
        <Box className="display-column">
          <Typography className="asset-title">{asset?.name}</Typography>
          {asset?.description !== undefined ? (
            <Typography
              sx={{
                mt: '1em',
                mb: '1em'
              }}
              className="asset-description"
            >
              {asset.description}
            </Typography>
          ) : null}
          {isDataSet ? generatePreviewButton() : null}
        </Box>
        {!isDataSet ? (
          <Box sx={{ pt: '1em' }} className="display-row insights-sub-sections">
            <Box className="display-column insights-assets-block">
              <Typography className="insights-sub-block-header">
                MODEL
              </Typography>
              {'type' in asset && asset?.type !== undefined ? (
                <Box className="insight-sub-block">
                  <Typography className="insight-label">Model type</Typography>
                  <Typography>{asset?.type}</Typography>
                </Box>
              ) : null}
              {performance !== null ? (
                <Box className="insight-sub-block">
                  <Typography className="insight-label">
                    Model precision
                  </Typography>
                  <Typography>{(+performance).toFixed(2)}</Typography>
                </Box>
              ) : null}
              {'percentageGain' in asset &&
              asset?.percentageGain !== undefined ? (
                <Box className="insight-sub-block">
                  <Typography className="insight-label">Comparision</Typography>
                  <Typography>{asset?.percentageGain.toFixed(2)}</Typography>
                </Box>
              ) : null}
            </Box>
            <Box className="display-column insights-assets-block">
              <Typography className="insights-sub-block-header">
                DATASET
              </Typography>
              {dataContext?.original_name !== undefined ? (
                <Box className="insight-sub-block">
                  <Typography className="insight-label">
                    Trained dataset
                  </Typography>
                  <Typography>{dataContext.original_name}</Typography>
                </Box>
              ) : null}
              {'importantFeatures' in asset &&
              asset?.importantFeatures !== undefined &&
              Array.isArray(asset?.importantFeatures) ? (
                <Box className="insight-sub-block">
                  <Typography className="insight-label">
                    Important features
                  </Typography>
                  <Typography>{asset?.importantFeatures.join(', ')}</Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box className="display-column insights-section-two insights-assets-block">
        <Box className="options-button">
          <CustomButton
            variant="secondary"
            style={{ padding: '10px' }}
            onClick={(event?: React.MouseEvent<HTMLButtonElement>) => {
              if (event !== undefined) {
                setAnchorEl(event.currentTarget);
              }
            }}
          >
            <MoreVertIcon />
          </CustomButton>
          <Menu
            id="basic-menu"
            className="option-basic-menu"
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {generateMenuList()}
          </Menu>
        </Box>
        <Box className="insight-sub-block">
          <Typography className="insight-label">Tags</Typography>
          <Box className="tags chips-wrapper">
            {tags.map((tag: string, index: number) => {
              if (tag === ACCESS_TYPES.TEMPORARILY_STORED) {
                return (
                  <Chip
                    className="chip temporarily-stored"
                    key={`${tag}-${index}`}
                    label={tag}
                  />
                );
              }

              return (
                <Chip key={`${tag}-${index}`} className="chip" label={tag} />
              );
            })}
          </Box>
        </Box>
        {('createdDate' in asset && asset.createdDate !== undefined) ||
        ('timeOfUsage' in asset && asset.timeOfUsage !== undefined) ? (
          <Box className="insight-sub-block">
            <Typography className="insight-label">
              {isDataSet ? 'Last updated' : 'Date created'}
            </Typography>
            <Typography>{generateDate()}</Typography>
          </Box>
        ) : null}
        <Box className="insight-sub-block">
          <Typography className="insight-label">Source</Typography>
          <Typography>{isDataSet ? 'Uploaded' : 'Assistant'}</Typography>
        </Box>
        <Box className="insight-sub-block">
          <Box className="access-items-center">
            <Typography className="insight-label">Access</Typography>
            <CustomTooltip />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '5px'
            }}
          >
            {getAssetAccess()}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const footerComponent = (
    <Box
      className={`${
        !insightsEditMode ? 'justify-space-between' : 'insights-edit-footer'
      }`}
    >
      {!insightsEditMode ? (
        <Box className="details-footer-left">
          <CustomButton
            variant="secondary"
            onClick={() => {
              if (isDataSet) {
                setModalController({
                  type: MODAL_TYPES.LOAD_DATASETS,
                  payload: {
                    disable_asset_button:
                      modalController?.payload?.disable_asset_button
                  }
                });
              } else {
                setModalController({
                  type: MODAL_TYPES.LOAD_MODELS,
                  payload: {
                    disable_asset_button:
                      modalController?.payload?.disable_asset_button
                  }
                });
              }
            }}
          >
            Previous
          </CustomButton>
          {isDataSet && disableUseAssetButton ? (
            <Select
              labelId="data-management-label"
              className="details-management-select"
              displayEmpty
              value=""
              renderValue={(value) =>
                value !== '' ? value : 'Data Management'
              }
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                PaperProps: {
                  sx: {
                    borderRadius: '16px'
                  }
                }
              }}
              onChange={({ target }) => {
                switch (target.value) {
                  case MODAL_TYPES.CROSS_TABS:
                    GAUserEvent(
                      `${userLocationVariable.current}_DATASET_${USER_TRACKING_DETAILS_ACTIONS.CROSSTABS_ACCESS}`
                    );
                    openCrossTabsView();
                    break;
                  case MODAL_TYPES.TRANSFORMATION:
                    GAUserEvent(
                      `${userLocationVariable.current}_DATASET_${USER_TRACKING_DETAILS_ACTIONS.FEAT_ENG_ACCESS}`
                    );
                    openDataTransformationView();
                    break;
                }
              }}
            >
              <MenuItem
                key={MODAL_TYPES.TRANSFORMATION + '0'}
                value={MODAL_TYPES.TRANSFORMATION}
                disabled={datasetSchemaPresent}
              >
                <CustomIcon type={ICON_TYPE.DATA_TRANSFORMATION} />
                Data Transformation
              </MenuItem>
              <MenuItem
                key={MODAL_TYPES.CROSS_TABS + '1'}
                value={MODAL_TYPES.CROSS_TABS}
              >
                <CustomIcon type={ICON_TYPE.CROSS_TABS} />
                Cross tabs
              </MenuItem>
            </Select>
          ) : null}
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ status: 'info', open: false, message: '' });
          }}
        >
          <Alert severity={snackBar.status}>{snackBar.message}</Alert>
        </Snackbar>
        <CustomButton variant="secondary" onClick={handleCancel}>
          Cancel
        </CustomButton>
        {!disableUseAssetButton || insightsEditMode ? (
          <CustomButton
            disabled={insightsEditMode && (!validName || sendData)}
            onClick={handleUseModel}
          >
            {insightsEditMode
              ? 'Save'
              : `Use this ${isDataSet ? 'Dataset' : 'Model'}`}
            {sendData && (
              <>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ margin: '0px 10px' }}
                />
                <LoadingDots />{' '}
              </>
            )}
          </CustomButton>
        ) : null}
      </Box>
    </Box>
  );

  return (
    <>
      <ModalComposed
        open={
          modalController.type === MODAL_TYPES.INSIGHT_MODEL ||
          modalController.type === MODAL_TYPES.INSIGHT_DATASETS
        }
        size={ModalSize.COMPOSED_FULL}
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {openDeleteModal && (
        <Delete
          messageError={[messageError, setMessageError]}
          closeDeleteModal={() => {
            setOpenDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default Details;
